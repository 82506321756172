import React, { FC } from 'react';
import { useCloseModal } from '@core/router';

import { CreateTranslationProvider } from '../contexts';
import { useParams } from 'react-router-dom';
import { UpdateTranslation } from '../containers';

export const UpdateTranslationPage: FC = () => {
    const closeModal = useCloseModal();
    const { id } = useParams<{ id?: string }>();

    const onClose = React.useCallback(() => {
        closeModal('/statuses');
    }, [closeModal]);

    return (
        <CreateTranslationProvider id={id} onCancel={onClose} onSucceed={onClose}>
            <UpdateTranslation />
        </CreateTranslationProvider>
    );
};

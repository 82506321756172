import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { ITranslation } from '../interfaces';

export type IGetTranslationRepoResponse = IRepoResponse<200, { data: ITranslation[]; total: number }> | IRepoResponse<400 | 422 | 500, string>;

export type IGetTranslationRepo = IRepo<[Record<string, any>], IGetTranslationRepoResponse>;

export const GetTranslationRepoType = Symbol.for('GetTranslationRepo');

@bind(GetTranslationRepoType)
export class GetTranslationRepo implements IGetTranslationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: Record<string, any>) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/translations', { sort_column: 'id', sort_order: 'desc', ...query }) });
      if (result.status === 200) {
        const data: ITranslation[] = result.data.data;
        const total = data.length;

        return {
          status: 200 as 200,
          response: {
            data,
            total,
          },
        };
      } else if (result.status === 422) {
        return {
          status: 422 as 422,
          response: result.data.errors.map((error) => Object.values(error.constraints).join('. ')).join(', '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

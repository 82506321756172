import { inject } from 'inversify';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { appendToFormData, urlMaker } from '@core/utils';

import { AuthMapper, LoginDtoMapper, LoginErrorMapper } from '../mappers';
import { LoginDto } from '../dto';
import { IAuth } from '../interfaces';
import { bind } from '@infra/container';

export type ILoginRepoResponse = IRepoResponse<200, IAuth> | IRepoResponse<400 | 401 | 403 | 500, string> | IRepoResponse<422>;

export type ILoginRepo = IRepo<[LoginDto], ILoginRepoResponse>;

export const LoginRepoType = Symbol.for('LoginRepo');

@bind(LoginRepoType)
export class LoginRepo implements ILoginRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(loginDto: LoginDto) {
    try {
      const mappedDto = LoginDtoMapper.toPersistence(loginDto);
      const body = new FormData();
      appendToFormData(mappedDto, body);

      const result = await this.httpClient.post({ url: urlMaker('/auth/login'), body });

      if (result.status === 200) {
        const mappedResponse = AuthMapper.toDomain(result.data);

        return {
          status: 200 as 200,
          response: mappedResponse,
        };
      } else if (result.status === 400 || result.status === 422) {
        const { error, errors } = result.data;

        if (error && error === 'invalid_grant') {
          return {
            status: 401 as 401,
            response: 'İstifadəçi məlumatları yalnış daxil edilib.',
          };
        } else if (errors) {
          const mappedErrors = LoginErrorMapper.toDomain(errors);

          return {
            status: 422 as 422,
            response: mappedErrors,
          };
        } else {
          return {
            status: 400 as 400,
            response: 'Əməliyyat aparıla bilmədi.',
          };
        }
      } else {
        return {
          status: 400 as 400,
          response: 'Əməliyyat aparıla bilmədi.',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TableState } from '@core/table';
import { useSub } from '@core/event-bus';

import { useTranslationsTableColumns } from '.';
import { TranslationsTableContext } from '../../contexts';

export const useTranslationsTable = () => {
    const { fetch, mergeState } = useContext(TranslationsTableContext);

    const history = useHistory();
    const location = useLocation<{ translationsTable?: { mergeState?: Partial<TableState> } }>();
    const columns = useTranslationsTableColumns();

    const getRowProps = useCallback(
        (id) => ({
            onDoubleClick: () => history.push(generatePath('/translations/:id', { id })),
        }),
        [history],
    );

    useSub(['@translations/create/succeed', '@translations/update/succeed', '@translations/remove/succeed'], fetch);

    useEffect(() => {
        if (location.state?.translationsTable?.mergeState) {
            mergeState(location.state.translationsTable.mergeState);
        }
    }, [location.state?.translationsTable?.mergeState, mergeState]);

    return { columns, getRowProps, context: TranslationsTableContext };
};

import * as React from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal } from 'antd';

import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { useTranslation } from 'react-i18next';

import { IRemoveTranslationRepo, RemoveTranslationRepoType } from '../../repos';
import { ITranslation } from '../../interfaces';
import { translationKeys } from '../../utils';
import { useService } from '@core/inversify-react';
import { TranslationsTableContext } from '../../contexts';

export const useTranslationsTableColumns = () => {
    const { fetch } = React.useContext(TranslationsTableContext);

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const removeTranslationRepo = useService<IRemoveTranslationRepo>(RemoveTranslationRepoType);

    const actionsColumn = React.useMemo<Column<ITranslation>>(
        () => ({
            ...tableColumns.actions,
            Cell: ({ row: { original } }) => {
                const openEdit = React.useCallback(() => {
                    history.push(generatePath('/translations/:id/update', { id: original.id }), { background: location });
                }, [original.id]);

                const remove = React.useCallback(() => {
                    const onOk = async () => {
                        const result = await removeTranslationRepo.execute(original.id);

                        if (result.status === 200) {
                            fetch();
                        } else {
                            message.error(result.response);
                        }
                    };

                    Modal.confirm({ title: t('attention'), content: t('deleteTxt'), onOk, okText: t('submit'), cancelText: t('close') });
                }, [original.id]);

                const overlay = (
                    <Menu>
                        <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
                            {t('update')}
                        </Menu.Item>
                        <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
                            {t('delete')}
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <StopPropagation>
                        <Dropdown overlay={overlay}>
                            <Button icon={<Icons.MoreOutlined />} size='small' />
                        </Dropdown>
                    </StopPropagation>
                );
            },
        }),
        [history, location, t, fetch, removeTranslationRepo],
    );

    const baseColumns = React.useMemo<Column<ITranslation>[]>(
        () => [
            {
                ...tableColumns.large,
                accessor: (row) => row.id,
                id: translationKeys.id,
                Header: t('code'),
            },
            {
                accessor: (row) => row.key,
                id: translationKeys.key,
                Header: t('lang'),
            },
            {
                accessor: (row) => row.name,
                id: `search[${translationKeys.name}]`,
                Header: t('key'),
            },
            {
                accessor: (row) => row.value,
                id: `search[${translationKeys.value}]`,
                Header: t('translation'),
            },
        ],
        [t],
    );

    return React.useMemo(() => {
        return [actionsColumn, ...baseColumns];
    }, [actionsColumn, baseColumns]);
};

import React, { FC, useCallback, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row } from 'antd';

import { TextField } from '@core/form';
import { AntForm } from '@core/ui';


import { CreateTranslationContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { useCreateTranslation } from '../hooks';
import { TranslationDto } from '../interfaces';

const FormikComponent: FC<FormikProps<TranslationDto>> = ({ handleSubmit, isSubmitting, values, setValues }) => {
    const { onCancel } = useContext(CreateTranslationContext);
    const { t } = useTranslation()

    const handleInputChange = useCallback((key: string, newValue: string) => {
        const translation = {
            key,
            value: newValue
        }
        const translations = values?.translations.filter((tr) => tr.key !== key)
        setValues({ ...values, translations: [translation, ...translations] })
    }, [setValues, values]);


    return (
        <Modal okText={t('submit')} cancelText={t('close')} width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={t('new')}>
            <AntForm layout='vertical' component='div' size='large'>
                <Row gutter={[24, 0]}>
                    <Col lg={24} xs={24}>
                        <TextField name='name' item={{ label: t('key'), }} input={{ placeholder: t('key') || '' }} />
                    </Col>
                    {values?.translations.length > 0 && values?.translations.map((translation) => (
                        <Col key={translation.key} lg={12} xs={24}>
                            <TextField name={translation.key} item={{ label: t(translation.key) }} input={{ placeholder: t(translation.key) || '', onChange: (e) => handleInputChange(translation.key, e.target.value) }} />
                        </Col>
                    ))}
                </Row>
            </AntForm>
        </Modal>
    );
};

export const CreateTranslation: FC = () => {
    const { initialValues, onSubmit } = useCreateTranslation()

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};

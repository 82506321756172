import 'reflect-metadata';
import 'moment/locale/az';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import antLocaleAz from './constants/antLocaleAz';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';

import { NextAdapter } from './@next';
import store from './core/configs/store';
import Router from './containers/Router';

import './less/main.less';
import './scss/main.scss';
import './core/utils/flat/flat';

moment.locale('az');

const root: JSX.Element = (
  <Suspense fallback={<h1>Loading</h1>}>
    <Provider store={store}>
      <ConfigProvider locale={antLocaleAz}>
        <NextAdapter>
          <Router />
        </NextAdapter>
      </ConfigProvider>
    </Provider>
  </Suspense>
);

const container: Element | null = document.querySelector('#root');

ReactDOM.render(root, container);

serviceWorker.unregister();

import { Tag, Tooltip } from 'antd';

export const OnlineStatus = ({ title, icon, bgColor, color }: { title: string; icon: JSX.Element; bgColor: string, color: string }) => {
    return (
        <Tooltip title={title}>
            <Tag style={{ color }} icon={icon} color={bgColor}>
                {title}
            </Tag>
        </Tooltip>
    );
};

import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IObjectMobileGroup } from '../interfaces';
import { ObjectMobileGroupsMapper } from '../mappers';

export type IGetMobileGroupsByObjectIdRepoResponse = IRepoResponse<200, IObjectMobileGroup> | IRepoResponse<400 | 500, string>;

export type IGetMobileGroupsByObjectIdRepo = IRepo<[number | string], IGetMobileGroupsByObjectIdRepoResponse>;

export const GetMobileGroupsByObjectIdRepoType = Symbol.for('GetMobileGroupsByObjectIdRepo');

@bind(GetMobileGroupsByObjectIdRepoType)
export class GetMobileGroupsByObjectIdRepo implements IGetMobileGroupsByObjectIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(object_id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker(`/objects/info/${object_id}`) });

      if (result.status === 200) {
        const data: IObjectMobileGroup = ObjectMobileGroupsMapper.toDomain(result.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

import { Fragment } from 'react';
import { Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { useTranslationsActionBar } from '../hooks';
import { useTranslation } from 'react-i18next';

export const TranslationsActionBar = () => {
    const {
        create,
        reset,
        fetch,
    } = useTranslationsActionBar();

    const { t } = useTranslation()

    return (
        <Fragment>
            <HeadPortal>
                <ActionBar $flex={true}>
                    <Space size={0}>
                        <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
                            {t('new')}
                        </HeaderButton>
                        <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
                            {t('refresh')}
                        </HeaderButton>
                        <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
                            {t('initialize')}
                        </HeaderButton>
                    </Space>
                </ActionBar>
            </HeadPortal>
        </Fragment>
    );
};

import { useLogout } from '@modules/auth';
import { CounterContext } from '@modules/counter';
import { SocketEventHandler, useSocketSubscribe } from '@modules/socket';
import * as React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export const EventsContext = React.createContext<null>(null);

export const EventsProvider: React.FC = React.memo(({ children }) => {
    const history = useHistory()
    const { refetch } = React.useContext(CounterContext)
    const { logout } = useLogout()

    const newAlarm = React.useCallback((alarm) => {
        history.push(generatePath('/alarms/:id/new', { id: alarm.id }))
    }, [history]);

    const events = React.useMemo<SocketEventHandler[]>(() => {
        return [
            { eventName: 'inspections:new', eventHandler: refetch },
            { eventName: 'alarm:create', eventHandler: newAlarm },
            { eventName: 'alarm:create', eventHandler: refetch },
            { eventName: 'alarm:update', eventHandler: refetch },
            { eventName: 'alarm:remove', eventHandler: refetch },
            { eventName: 'logout', eventHandler: logout }
        ]
    }, [refetch, logout, newAlarm])

    useSocketSubscribe(events)

    return <EventsContext.Provider value={null}>{children}</EventsContext.Provider>;
});

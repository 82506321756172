import Cookies from 'js-cookie';
import React, { createContext, useRef, useEffect, memo, useState } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';

type SocketType = Socket | null

interface ISocketContext {
  socket: SocketType,
  isConnected: Boolean
}

const initialState: ISocketContext = {
  socket: null,
  isConnected: false
}

export const SocketContext = createContext<ISocketContext>(initialState);

export const SocketProvider: React.FC = memo(({ children }) => {
  const token = Cookies.get('accessToken');
  const socketInstance = socketIOClient(process.env.REACT_APP_API_HOST || '', {
    auth: { token },
    reconnectionAttempts: 3,
    retries: 3,
  })

  const socket = useRef<SocketType>(socketInstance);
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const currentSocket = socket.current;
    if (currentSocket) {
      currentSocket.on('connect', () => {
        setIsConnected(true)
      });

      currentSocket.on('error', (msg: string) => {
        console.error('SocketIO: Error', msg);
      });

      currentSocket.on('disconnect', () => {
        setIsConnected(false)
      })
    }

    return () => {
      if (currentSocket) {
        currentSocket.removeAllListeners();
        currentSocket.close();
      }
    };
  }, []);

  return <SocketContext.Provider value={{ socket: socket.current, isConnected }}>{children}</SocketContext.Provider>;
});

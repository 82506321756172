import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { TranslationDto } from '../interfaces';

export type ICreateTranslationRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type ICreateTranslationRepo = IRepo<[TranslationDto], ICreateTranslationRepoResponse>;

export const CreateTranslationRepoType = Symbol.for('CreateTranslationRepo');

@bind(CreateTranslationRepoType)
export class CreateTranslationRepo implements ICreateTranslationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: TranslationDto) {
    try {
      const body = dto;
      const result = await this.httpClient.post({ url: urlMaker('/translations/group'), body: JSON.stringify(body) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type LogoutRepoResponse = IRepoResponse<200, null> | IRepoResponse<400 | 500, string>;

export type ILogoutRepo = IRepo<[], LogoutRepoResponse>;

export const LogoutRepoType = Symbol.for('LogoutRepo');

@bind(LogoutRepoType)
export class LogoutRepo implements ILogoutRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/auth/logout') });
      this.httpClient.removeHeader('Authorization');

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Əməliyyat aparıla bilmədi.',
        };
      }
    } catch (e) {
      return {
        status: 400 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { useTranslationsTableContext } from '../hooks';
import { TranslationsTable, TranslationsActionBar } from '../containers';

export const TranslationsPage = () => {
    const { onFetch, context, defaultTableState } = useTranslationsTableContext();

    return (
        <TableProvider name='translations' onFetch={onFetch} context={context} defaultState={defaultTableState}>
            <PageContent $contain={true}>
                <TranslationsActionBar />
                <TranslationsTable />
            </PageContent>
        </TableProvider>
    );
};

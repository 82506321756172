import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { SuspenseSpin } from '@core/ui';

import { useAuth } from '@modules/auth';
import { AppLayout } from '@modules/layout';
import routes from '../../constants/routes';


const SettingsRouter = React.lazy(() => import('@modules/settings/router/settings.router'))

// Users Routers
const UsersRouter = React.lazy(() => import('@modules/users/router/users.router'));
const UsersModalsRouter = React.lazy(() => import('@modules/users/router/users-modal.router'));

// Reasons Routers
const ReasonsRouter = React.lazy(() => import('@modules/reasons/router/reasons.router'));
const ReasonsModalRouter = React.lazy(() => import('@modules/reasons/router/reasons-modal.router'));


// Requests Routers
const M2GRequestsRouter = React.lazy(() => import('@modules/m2g-requests/router/m2g-requests.router'))
const M2GRequestModalRouter = React.lazy(() => import('@modules/m2g-requests/router/m2g-requests-modal.router'))

// G2M Request Routers

const G2MRequestsRouter = React.lazy(() => import('@modules/g2m-requests/router/g2m-requests.router'))
const G2MRequestModalRouter = React.lazy(() => import('@modules/g2m-requests/router/g2m-requests-modal.router'))


// Statuses Routers
const StatusesRouter = React.lazy(() => import('@modules/statuses/router/statuses.router'));
const StatusesModalRouter = React.lazy(() => import('@modules/statuses/router/statuses-modal.router'));

//Objects Routers
const ObjectsRouter = React.lazy(() => import('@modules/objects/router/objects.router'));
const ObjectsModalROuter = React.lazy(() => import('@modules/objects/router/objects-modal.router'))

//Regions Routers
const RegionsRouter = React.lazy(() => import('@modules/regions/router/regions.router'));

//Objects Routers
const MobileGroupsRouter = React.lazy(() => import('@modules/mobile-groups/router/mobile-groups.router'));

//Object Check Routers
const InspectionsRouter = React.lazy(() => import('@modules/inspections/router/inspection.router'))
const InspectionsModalRouter = React.lazy(() => import('@modules/inspections/router/inspection-modal.router'))

// Translations Routers
const TranslationsRouter = React.lazy(() => import('@modules/translations/router/translations.router'))
const TranslationsModalRouter = React.lazy(() => import('@modules/translations/router/translations-modal.router'));

// Alarms Routers
const AlarmsRouter = React.lazy(() => import('@modules/alarms/router/alarms.router'))
const AlarmsModalRouter = React.lazy(() => import('@modules/alarms/router/alarms-modal.router'))


export const NextRouter = () => {
  const location = useLocation<any>();
  const { user, auth } = useAuth();

  if (auth.loading || user.loading) {
    return <SuspenseSpin />;
  }
  return (
    <React.Suspense fallback={<SuspenseSpin />}>
      {!!user.data ? (
        <AppLayout>
          <React.Suspense fallback={<SuspenseSpin />}>
            <Switch location={location.state?.background || location}>
              <Route path='/settings' component={SettingsRouter} />
              <Route path='/users' component={UsersRouter} />
              <Route path='/reasons' component={ReasonsRouter} />
              <Route path='/m2g-requests' component={M2GRequestsRouter} />
              <Route path='/g2m-requests' component={G2MRequestsRouter} />
              <Route path='/inspections' component={InspectionsRouter} />
              <Route path='/statuses' component={StatusesRouter} />
              <Route path='/regions' component={RegionsRouter} />
              <Route path='/objects' component={ObjectsRouter} />
              <Route path='/mobile-groups' component={MobileGroupsRouter} />
              <Route path='/translations' component={TranslationsRouter} />
              <Route path='/alarms' component={AlarmsRouter} />
              <Redirect exact from='/' to='/objects' />
            </Switch>
          </React.Suspense>
          <Switch location={location.state?.modal || location}>
            <Route path='/users' component={UsersModalsRouter} />
            <Route path='/inspections' component={InspectionsModalRouter} />
            <Route path='/statuses' component={StatusesModalRouter} />
            <Route path='/reasons' component={ReasonsModalRouter} />
            <Route path='/m2g-requests' component={M2GRequestModalRouter} />
            <Route path='/g2m-requests' component={G2MRequestModalRouter} />
            <Route path='/objects' component={ObjectsModalROuter} />
            <Route path='/translations' component={TranslationsModalRouter} />
            <Route path='/alarms' component={AlarmsModalRouter} />
          </Switch>
        </AppLayout>
      ) : (
        !user.loading && !user.data && <Redirect to={routes.login.index} />
      )}
    </React.Suspense>
  );
};

import { useCallback } from 'react';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { ILogoutRepo, LogoutRepoType, useAuth } from '@modules/auth';

export const useLogout = () => {
  const logoutRepo = useService<ILogoutRepo>(LogoutRepoType);
  const { resetAuth } = useAuth();

  const logout = useCallback(async () => {
    message.loading('Əməliyyat aparılır...');
    await logoutRepo.execute();
    resetAuth();
    message.destroy();
  }, [logoutRepo, resetAuth]);

  return {
    logout,
  };
};

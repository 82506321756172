import i18n from 'i18next';
import { getI18n, initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend'
import languageDetector from 'i18next-browser-languagedetector';

import { HttpClient } from '@core/http';
import { urlMaker } from '@core/utils';

const httpClient = new HttpClient();

const url = urlMaker("/translations/group");

const loadResources = async () => {
  return await httpClient.get({ url })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => { console.log(error); });
}

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options: any, url: any, payload: any, callback: any) => {
    try {
      const [lng] = url.split('|');
      loadResources()
        .then((response) => {
          const mapped: Record<string, any> = {};
          Object.values(response).forEach((elem: any) => {
            const lang = elem.find(item => item.key === lng);
            mapped[lang['name']] = lang['value'];
          })
          callback(null, {
            data: mapped,
            status: 200,
          });
        });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(HttpBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    lng: localStorage.getItem('i18nextLng') || 'az',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => {
    console.error(e);
  });

export const translate = (keyword: string): string => {
  return getI18n().t(keyword);
};

export default i18n;
import { inject } from 'inversify';

import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { CounterMapper } from '../mappers';
import { ICounter } from '../interfaces';

export type GetCounterRepoResponse = IRepoResponse<200, ICounter> | IRepoResponse<400 | 500, string>;

export type IGetCounterRepo = IRepo<[], GetCounterRepoResponse>;

export const GetCounterRepoType = Symbol.for('GetCounterRepo');

@bind(GetCounterRepoType)
export class GetCounterRepo implements IGetCounterRepo {
  constructor(@inject(HttpClientType) private httpClient: IHttpClient) {}

  async execute() {
    try {
      const response = await this.httpClient.get({ url: urlMaker('/alarms/check/total') });

      if (response.status === 200) {
        const mappedData = CounterMapper.toDomain(response.data);
        return {
          status: 200 as 200,
          response: mappedData,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi.',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row } from 'antd';

import { TextField } from '@core/form';
import { AntForm } from '@core/ui';


import { CreateTranslationContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { useUpdateTranslation } from '../hooks';
import { ITranslation } from '../interfaces';

const FormikComponent: FC<FormikProps<ITranslation>> = ({ handleSubmit, isSubmitting }) => {
    const { onCancel } = useContext(CreateTranslationContext);
    const { t } = useTranslation()

    return (
        <Modal okText={t('submit')} cancelText={t('close')} width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={t('update')}>
            <AntForm layout='vertical' component='div' size='large'>
                <Row gutter={[24, 0]}>
                    <Col lg={24} xs={24}>
                        <TextField name='key' item={{ label: t('lang'), }} input={{ disabled: true, placeholder: t('lang') || '' }} />
                    </Col>
                    <Col lg={24} xs={24}>
                        <TextField name='name' item={{ label: t('name'), }} input={{ disabled: true, placeholder: t('name') || '' }} />
                    </Col>
                    <Col lg={24} xs={24}>
                        <TextField name='value' item={{ label: t('translation'), }} input={{ placeholder: t('translation') || '' }} />
                    </Col>
                </Row>
            </AntForm>
        </Modal>
    );
};

export const UpdateTranslation: FC = () => {
    const { initialValues, onSubmit } = useUpdateTranslation()

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};

import React, { FC, useEffect, useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Card, Form, Layout, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { CardProps } from 'antd/lib/card';
import { BasicProps as LayoutProps } from 'antd/lib/layout/layout';
import { ButtonProps } from 'antd/lib/button';

import { InputField, PasswordField } from '../components/ReduxForm/Input';
import { CheckboxField } from '../components/ReduxForm/Checkbox';
import FormError from '../components/ReduxForm/Alert';
import { AlertProps } from 'antd/lib/alert';
import { submitAction } from '../actions/login';
import { useSelector } from 'react-redux';
import { baseModelSelector } from '../selectors/common';
import { IAuthState } from '../interfaces/auth/state';
import { removeAllCookies } from '../core/utils/remove-all-cookies';
import { useTitle } from '../hooks/common/useTitle';

const LoginPage: FC<InjectedFormProps> = ({ submitting, error, handleSubmit, reset }) => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Daxil ol`);
  const auth = useSelector(baseModelSelector<IAuthState>('auth'));

  const actions = useMemo(
    () => [
      <Typography.Paragraph type='secondary' className='paragraph-no-space' key='company'>
        {process.env.REACT_APP_COMPANY_NAME} MMC &copy; 2020
      </Typography.Paragraph>,
    ],
    [],
  );

  const layoutProps = useMemo<LayoutProps>(
    () => ({
      id: 'login-layout',
      className: 'one-page-layout',
    }),
    [],
  );

  const cardProps = useMemo<CardProps>(
    () => ({
      id: 'login-form-card',
      title: 'Hesabınıza daxil olun',
      actions: actions,
      bordered: false,
    }),
    [actions],
  );

  const formProps = useMemo<FormProps>(
    () => ({
      layout: 'vertical',
      size: 'large',
      className: 'ant-next-form-small-space',
    }),
    [],
  );

  const emailFieldProps = useMemo(
    () => ({
      name: 'username',
      field: { placeholder: 'İstifadəçi adı' },
      component: InputField,
    }),
    [],
  );

  const passwordFieldProps = useMemo(
    () => ({
      name: 'password',
      field: { placeholder: 'Şifrə' },
      component: PasswordField,
    }),
    [],
  );

  const rememberMeFieldProps = useMemo(
    () => ({
      name: 'rememberMe',
      type: 'checkbox',
      field: { children: 'Yadda saxla' },
      item: { className: 'ant-next-form-item-checkbox-small' },
      component: CheckboxField,
    }),
    [],
  );

  const submitButtonProps = useMemo<ButtonProps>(
    () => ({
      className: 'full-width',
      type: 'primary',
      children: 'Daxil ol',
      loading: submitting || auth.loading,
      onClick: handleSubmit,
    }),
    [submitting, handleSubmit, auth.loading],
  );

  const formErrorProps = useMemo<AlertProps>(
    () => ({
      message: error,
      onClose: reset,
    }),
    [error, reset],
  );

  useEffect(() => {
    removeAllCookies();
  }, []);

  return (
    <Layout {...layoutProps}>
      <Card {...cardProps}>
        <Form {...formProps}>
          <FormError {...formErrorProps} />
          <Field {...emailFieldProps} />
          <Field {...passwordFieldProps} />
          <Field {...rememberMeFieldProps} />
          <Button {...submitButtonProps} />
        </Form>
      </Card>
    </Layout>
  );
};

export default React.memo(reduxForm({ form: 'login', onSubmit: submitAction })(LoginPage));

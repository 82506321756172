import * as React from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { useAuth } from '@modules/auth';

import { GetCounterRepoType, IGetCounterRepo } from '../repos';
import { ICounter } from '../interfaces';

const initialState: ICounter = {
    alarms: { new: 0, total: 0 },
    inspections: { new: 0, total: 0 }
};

export const CounterContext = React.createContext<{ state: ICounter, refetch: () => void }>({
    state: initialState,
    refetch: () => { }
});

export const CounterProvider: React.FC = React.memo(({ children }) => {
    const auth = useAuth();
    const getCounterRepo = useService<IGetCounterRepo>(GetCounterRepoType);

    const { data, refetch } = useQuery<ICounter>(
        ['counter'],
        async () => {
            const result = await getCounterRepo.execute();

            if (result.status === 200) {
                return result.response;
            } else {
                throw new Error(result.response);
            }
        },
        {
            initialData: initialState,
            enabled: !!auth.user.data,
        },
    );

    if (!data) {
        return null;
    }

    return <CounterContext.Provider value={{ state: data, refetch }}>{children}</CounterContext.Provider>;
});

import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { ILanguage } from '../interfaces';

export type IGetLanguagesRepoResponse = IRepoResponse<200, ILanguage[]> | IRepoResponse<400 | 500, string>;

export type IGetLanguagesRepo = IRepo<[], IGetLanguagesRepoResponse>;

export const GetLanguagesRepoType = Symbol.for('GetLanguagesRepo');

@bind(GetLanguagesRepoType)
export class GetLanguagesRepo implements IGetLanguagesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/languages') });

      if (result.status === 200) {
        const data: ILanguage[] = result.data.data;

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

import * as React from 'react';
import Cookies from 'js-cookie';

import { useService } from '@core/inversify-react';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';

import { GetAuthUserRepoType, IGetAuthUserRepo } from '../repos';
import { IAuth, IAuthContext, IAuthUser } from '../interfaces';

const initialState: IAuthContext = {
  auth: {
    loading: true,
    error: null,
    data: null,
  },

  user: {
    loading: false,
    error: null,
    data: null,
  },

  setAuthData: () => { },
  setAuthLoading: () => { },
  setAuthError: () => { },
  resetAuth: () => { },

  setUserData: () => { },
  setUserLoading: () => { },
  setUserError: () => { },

};

export const AuthContext = React.createContext<IAuthContext>(initialState);

export const AuthProvider: React.FC = React.memo(({ children }) => {
  const httpClient = useService<IHttpClient>(HttpClientType);
  const getAuthUserRepo = useService<IGetAuthUserRepo>(GetAuthUserRepoType);

  const [auth, setAuth] = React.useState(initialState.auth);
  const [user, setUser] = React.useState(initialState.user);

  const setAuthData = React.useCallback((auth: IAuth) => {
    setAuth((value) => ({ ...value, data: auth, loading: false }));
  }, []);

  const setAuthLoading = React.useCallback((loading: boolean) => {
    setAuth((value) => ({ ...value, loading }));
  }, []);

  const setAuthError = React.useCallback((error: string | null) => {
    setAuth((value) => ({ ...value, error, loading: false }));
  }, []);

  const resetAuth = React.useCallback(() => {
    setAuth({ ...initialState.auth, loading: false });
    setUser({ ...initialState.user, loading: false });
  }, []);

  const setUserData = React.useCallback((auth: IAuthUser) => {
    setUser(() => ({ data: auth, loading: false, error: null }));
  }, []);

  const setUserLoading = React.useCallback((loading: boolean) => {
    setUser((value) => ({ ...value, loading }));
  }, []);

  const setUserError = React.useCallback((error: string | null) => {
    setUser(() => ({ error, loading: false, data: null }));
  }, []);

  React.useEffect(() => {
    setAuthLoading(true);
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken') || '';
    const tokenType = Cookies.get('tokenType') || '';
    const expiresIn = 0;

    if (accessToken) {
      setAuthData({ accessToken, refreshToken, tokenType, expiresIn });
      httpClient.setHeader('Authorization', `Bearer ${accessToken}`);

      (async () => {
        const cachedUser = JSON.parse(localStorage.getItem('authedUser') || 'null');

        if (!cachedUser) {
          setUserLoading(true);
        } else {
          setUserData(cachedUser);
        }

        const userResult = await getAuthUserRepo.execute();

        if (userResult.status === 200) {
          localStorage.setItem('authedUser', JSON.stringify(userResult.response));
          setUserData(userResult.response);
        } else {
          setUserError(userResult.response);
          setAuthError(null);
        }
      })();
    } else {
      setAuthLoading(false);
    }
  }, [getAuthUserRepo, httpClient, setAuthData, setAuthError, setAuthLoading, setUserData, setUserError, setUserLoading]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        setAuthData,
        setAuthLoading,
        setAuthError,
        resetAuth,
        setUserData,
        setUserLoading,
        setUserError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

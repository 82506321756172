import * as React from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import * as UI from '../components';
import { useSidebar } from '../hooks';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/images/logoNew.png'

export const AppSidebar = () => {
  const { isOpen, activeKey, toggleSidebar, counter } = useSidebar();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <UI.Sider trigger={null} width={224} collapsible={true} collapsedWidth={46} collapsed={!isOpen} className={isOpen ? 'active' : 'inactive'}>
        <UI.Brand>
          <img src={Logo} alt={process.env.REACT_APP_COMPANY_NAME} />
        </UI.Brand>
        <Menu theme='dark' mode='inline' selectedKeys={[activeKey]}>
          <Menu.ItemGroup title={t("general")}>
            <Menu.Item key='/objects' icon={<Icons.SettingOutlined />}>
              {t("objects")}
              <NavLink to='/objects' />
            </Menu.Item>
            <Menu.Item key='/alarms' icon={<Icons.AlertOutlined />}>
              {t("alarms")} ({counter.alarms.new}/{counter.alarms.total})
              <NavLink to='/alarms' />
            </Menu.Item>
            <Menu.Item key='/inspections' icon={<Icons.CheckCircleOutlined />}>
              {t("inspections")} ({counter.inspections.new}/{counter.inspections.total})
              <NavLink to='/inspections' />
            </Menu.Item>
            <Menu.Item key='/mobile-groups' icon={<Icons.MobileOutlined />}>
              {t("mobileGroups")}
              <NavLink to='/mobile-groups' />
            </Menu.Item>
            <Menu.Item key='/users' icon={<Icons.UserOutlined />}>
              {t("clients")}
              <NavLink to='/users' />
            </Menu.Item>
            <Menu.Item key='/regions' icon={<Icons.GlobalOutlined />}>
              {t("regions")}
              <NavLink to='/regions' />
            </Menu.Item>
            <Menu.ItemGroup title={t("system")}>
              <Menu.Item key='/m2g-requests' icon={<Icons.HistoryOutlined />}>
                {t("masToGuardnet")}
                <NavLink to='/m2g-requests' />
              </Menu.Item>
              <Menu.Item key='/g2m-requests' icon={<Icons.HistoryOutlined />}>
                {t("guardnetToMas")}
                <NavLink to='/g2m-requests' />
              </Menu.Item>
              <Menu.Item key='/reasons' icon={<Icons.QuestionCircleOutlined />}>
                {t("reasons")}
                <NavLink to='/reasons' />
              </Menu.Item>
              <Menu.Item key='/statuses' icon={<Icons.StarOutlined />}>
                {t("statuses")}
                <NavLink to='/statuses' />
              </Menu.Item>
              <Menu.Item key='/translations' icon={<Icons.MobileOutlined />}>
                {t("translations")}
                <NavLink to='/translations' />
              </Menu.Item>
              <Menu.Item key='/settings' icon={<Icons.SettingOutlined />}>
                {t("settings")}
                <NavLink to='/settings' />
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.ItemGroup>
        </Menu>
      </UI.Sider>
      <UI.SiderOverlay $visible={isOpen} onClick={toggleSidebar} />
    </React.Fragment>
  );
};

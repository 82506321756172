import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IResyncObjectsRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IResyncObjectsRepo = IRepo<[], IResyncObjectsRepoResponse>;

export const ResyncObjectsRepoType = Symbol.for('ResyncObjectsRepoType');

@bind(ResyncObjectsRepoType)
export class ResyncObjectsRepo implements IResyncObjectsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.post({ url: urlMaker(`/objects/sync`) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else {
        return {
          status: 400 as 400,
          response: result.data.errors.map((error) => Object.values(error).join('. ')).join('. ') || 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

import { ICounter, ICounterPersistence } from '../interfaces';

export class CounterMapper {
  public static toDomain(counter: ICounterPersistence): ICounter {
    return {
      alarms: {
        total: counter.total_alarms,
        new: counter.new_alarms,
      },
      inspections: {
        total: counter.total_inspection,
        new: counter.new_inspection,
      },
    };
  }
}

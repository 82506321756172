import { useCallback, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TranslationsTableContext } from '../contexts';

export const useTranslationsActionBar = () => {
    const { selection, selectAll, fetch, reset, resetSelection } = useContext(TranslationsTableContext);
    const location = useLocation();
    const history = useHistory();
    const { role } = useParams<{ role?: string }>();

    const create = useCallback(() => {
        history.push('/translations/create', { background: location });
    }, [history, location]);

    return {
        selection,
        resetSelection,
        create,
        selectAll,
        reset,
        fetch,
        role,
    };
};

import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { IGetTranslationByIdRepo, GetTranslationByIdRepoType } from '../repos';
import { ITranslation } from '../interfaces';

export const useTranslationById = (id?: string, queryOptions?: UseQueryOptions<ITranslation, Error, ITranslation, string[]>) => {
    const getTranslationByIdRepo = useService<IGetTranslationByIdRepo>(GetTranslationByIdRepoType);

    return useQuery<ITranslation, Error, ITranslation, any[]>(
        ['translationbyid', id],
        async ({ queryKey }) => {
            const [, id] = queryKey;
            const result = await getTranslationByIdRepo.execute(id);
            if (result.status === 200) {
                if (result.response.length > 0) {
                    return result.response[0]
                }
                return {};
            } else {
                throw new Error(result.response);
            }
        },
        queryOptions,
    );
};

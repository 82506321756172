import { injectable } from 'inversify';
import type { IHttpResponse, IHttpClient, IHttpHeaders, IHttpMethod, IHttpRequest } from './interfaces';

@injectable()
export class HttpClient implements IHttpClient {
  private defaultHeaders: IHttpHeaders = {
    Accept: 'application/json',
  };

  public getHeader(key: string) {
    return this.defaultHeaders[key];
  }

  public getHeaders() {
    return this.defaultHeaders;
  }

  public setHeader(key: string, value: string) {
    this.defaultHeaders[key] = value;
  }

  public removeHeader(key: string) {
    delete this.defaultHeaders[key];
  }

  private async makeRequest<HttpResponse extends IHttpResponse<any, any>>(request: IHttpRequest, method: IHttpMethod): Promise<HttpResponse> {
    const mergedHeaders = { ...this.defaultHeaders, ...request.headers, 'content-type': 'application/json' };
    const response: Response = await fetch(request.url, {
      method: method,
      headers: mergedHeaders,
      body: request.body,
    });
    const clonedResponse = response.clone();

    const status: number = response.status;
    const contentType = response.headers.get('Content-Type');
    let data: any;

    if (contentType?.includes('application/json')) {
      data = await response.json();
    }

    return {
      status,
      data,
      blob: async () => await clonedResponse.blob(),
      text: async () => await clonedResponse.text(),
      arrayBuffer: async () => await clonedResponse.arrayBuffer(),
      formData: async () => await clonedResponse.formData(),
      rawResponse: clonedResponse,
    } as any;
  }

  get<HttpResponse extends IHttpResponse<any, any>>(request: IHttpRequest): Promise<HttpResponse> {
    return this.makeRequest<HttpResponse>(request, 'get');
  }
  post<HttpResponse extends IHttpResponse<any, any>>(request: IHttpRequest): Promise<HttpResponse> {
    return this.makeRequest<HttpResponse>(request, 'post');
  }
  put<HttpResponse extends IHttpResponse<any, any>>(request: IHttpRequest): Promise<HttpResponse> {
    return this.makeRequest<HttpResponse>(request, 'put');
  }
  delete<HttpResponse extends IHttpResponse<any, any>>(request: IHttpRequest): Promise<HttpResponse> {
    return this.makeRequest<HttpResponse>(request, 'delete');
  }
}

export const HttpClientType = Symbol.for('HttpClient');

import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { fillObject, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { StartAlarmMapper } from '../mappers';
import { StartAlarmDto } from '../dtos';
import { IObject } from '../interfaces';

export type IStartAlarmRepoResponse = IRepoResponse<200, IObject> | IRepoResponse<400 | 500, string>;

export type IStartAlarmRepo = IRepo<[StartAlarmDto, string | number], IStartAlarmRepoResponse>;

export const StartAlarmRepoType = Symbol.for('StartAlarmRepoType');

@bind(StartAlarmRepoType)
export class StartAlarmRepo implements IStartAlarmRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: StartAlarmDto, id: string | number) {
    try {
      const mappedBody = StartAlarmMapper.toPersistence(dto);
      const body = fillObject(mappedBody);
      const result = await this.httpClient.post({ url: urlMaker(`/objects/${id}/start-alarm`), body: JSON.stringify(body) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: result.data.data,
        };
      } else {
        return {
          status: 400 as 400,
          response: result.data.errors.map((error) => Object.values(error).join('. ')).join('. ') || 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

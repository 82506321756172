import * as React from 'react';

export type ICreateTranslationContext = {
    id?: string
    onSucceed: () => void;
    onCancel: () => void;
};

const defaultValues: ICreateTranslationContext = {
    onSucceed: () => { },
    onCancel: () => { },
};

export const CreateTranslationContext = React.createContext<ICreateTranslationContext>(defaultValues);

export const CreateTranslationProvider: React.FC<Partial<ICreateTranslationContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
    return <CreateTranslationContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateTranslationContext.Provider>;
};

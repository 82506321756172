import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';
import { ICreateTranslationRepo, CreateTranslationRepoType } from '../repos';
import { CreateTranslationContext } from '../contexts';
import { TranslationDto } from '../interfaces';
import { useLanguages } from '../hooks';

export const useCreateTranslation = () => {
  const { onSucceed } = useContext(CreateTranslationContext);

  const eventBus = useBus();
  const createTranslationRepo = useService<ICreateTranslationRepo>(CreateTranslationRepoType);

  const { data: languages } = useLanguages();

  const initialValues = useMemo<TranslationDto>(() => {
    const value: TranslationDto = { name: '', translations: [] };
    languages?.forEach(({ key }) => {
      value['translations'].push({ key, value: '' });
    });
    return value;
  }, [languages]);

  const onSubmit = useCallback(
    async (values: TranslationDto, helpers: FormikHelpers<TranslationDto>) => {
      helpers.setStatus(null);
      const result = await createTranslationRepo.execute(values);
      if (result.status === 200) {
        eventBus.publish({ type: '@translations/create/succeed' });
        onSucceed?.();
      } else {
        message.error(result.response);
      }
    },
    [createTranslationRepo, eventBus, onSucceed],
  );

  return { onSubmit, initialValues };
};

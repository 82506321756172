import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';
import { IUpdateTranslationRepo, UpdateTranslationRepoType } from '../repos';
import { CreateTranslationContext } from '../contexts';
import { ITranslation } from '../interfaces';
import { useTranslationById } from './use-translation-by-id';

export const useUpdateTranslation = () => {
  const { onSucceed, id } = useContext(CreateTranslationContext);

  const eventBus = useBus();
  const updateTranslationRepo = useService<IUpdateTranslationRepo>(UpdateTranslationRepoType);

  const translationById = useTranslationById(id);

  const initialValues = useMemo<ITranslation>(() => {
    if (id && translationById.data) {
      return translationById.data;
    }
    return {
      id: '',
      key: '',
      name: '',
      value: '',
    };
  }, [translationById, id]);

  const onSubmit = useCallback(
    async (values: ITranslation, helpers: FormikHelpers<ITranslation>) => {
      helpers.setStatus(null);
      const result = await updateTranslationRepo.execute(values, id?.toString() || values.id);
      if (result.status === 200) {
        eventBus.publish({ type: '@translations/update/succeed' });
        onSucceed?.();
      } else {
        message.error(result.response);
      }
    },
    [updateTranslationRepo, eventBus, onSucceed, id],
  );

  return { onSubmit, initialValues };
};

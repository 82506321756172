import { caller, urlFactory } from '../core/utils/caller';
import Cookies from 'js-cookie';
import { UnexpectedError } from '../core/helpers/errors';
import { message } from 'antd';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { container } from '@infra/container';

export const submitAction = async (body) => {
  const httpClient = container.get<IHttpClient>(HttpClientType);
  const url = urlFactory('/auth/login');
  try {
    const response = await caller(url, { method: 'POST', body: JSON.stringify(body) });

    if (response.ok) {
      const { token, expires_in } = await response.json();
      Cookies.remove('access_token');
      Cookies.remove('accessToken');
      Cookies.set('access_token', token, { expires: body.rememberMe ? expires_in : undefined });
      Cookies.set('accessToken', token, { expires: body.rememberMe ? expires_in : undefined });
      httpClient.setHeader('Authorization', `Bearer ${token}`);
      window.location.replace('/');
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error('Məlumatlar yalnış daxil edilib.');
  }
};

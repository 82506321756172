import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import * as UI from '../components';
import { useHeader } from '../hooks';
import { QuickSearch } from '../components/quick-search';
import { useTranslation } from 'react-i18next';
import { OnlineStatus, useSocketStatus } from '@modules/socket';
import az from '../../../../assets/icons/az.svg'
import en from '../../../../assets/icons/en.svg'

export const AppHeader = () => {
  const { toggleSidebar, sidebarIsOpen, avatarText, logout, changeLanguage, languages } = useHeader();
  const { t } = useTranslation();
  const status = useSocketStatus()

  return (
    <UI.Header theme='dark' $wide={!sidebarIsOpen}>
      <UI.HeaderLeft>{sidebarIsOpen ? <UI.MenuFoldIcon onClick={toggleSidebar} /> : <UI.MenuUnfoldIcon onClick={toggleSidebar} />}</UI.HeaderLeft>
      <UI.HeaderPortalArea />
      <UI.HeaderRight>
        <QuickSearch />
        <OnlineStatus {...status} />
        <Dropdown
          overlay={
            <Menu>
              {languages?.map((language) =>
                <Menu.Item key={language.id} onClick={() => changeLanguage(language.key)}>
                  <img style={{
                    marginRight: '0.5rem',
                    width: '20px',
                    height: '20px'
                  }} src={language.key === 'az' ? az : en} alt={language.name} />
                  {language.name}
                </Menu.Item>
              )}
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                {t('exit')}
              </Menu.Item>
            </Menu>
          }
        >
          <UI.HeaderButton>
            <Avatar>{avatarText}</Avatar>
          </UI.HeaderButton>
        </Dropdown>
      </UI.HeaderRight>
    </UI.Header>
  );
};

import { useState, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Input, message } from 'antd';
import * as Icons from '@ant-design/icons';
import { HeaderButton } from '../components/header';
import { useService } from '@core/inversify-react';
import { GetObjectsRepoType, IGetObjectsRepo } from '@modules/objects/repos';
import { generatePath, useHistory } from 'react-router-dom';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useTranslation } from 'react-i18next';

export const Form = styled.form`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
`;

export const QuickSearch = () => {
  const { t } = useTranslation()
  const [isInputShown, setIsInputShown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory()
  const getObjectByNob = useService<IGetObjectsRepo>(GetObjectsRepoType)

  const checkObject = useCallback(
    async (nob: string) => {
      const tableQuery: TableFetchParams = {
        filters: [{ id: 'nob', value: nob }],
        pageIndex: 0,
        pageSize: 25,
        sortBy: []
      }

      const result = await getObjectByNob.execute(tableQueryMaker(tableQuery));

      if (result.status === 200) {
        if (!!result.response.data.length) {
          const objectId = result.response.data[0].id;
          history.push(generatePath('/objects/:id/details', { id: objectId }));
        } else {
          message.error("Nəticə tapılmadı.")
        }
      } else {
        message.error(result.response);
      }
    },
    [getObjectByNob, history],
  );


  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      checkObject(searchInput);
    },
    [checkObject, searchInput],
  );

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    setIsInputShown(false);
  }, []);

  const onButtonClick = useCallback(() => {
    setIsInputShown(true);
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      {isInputShown && <Input autoFocus={true} placeholder={t('objectCode') || ''} value={searchInput} onChange={onInputChange} onBlur={handleBlur} />}
      <HeaderButton onClick={onButtonClick} icon={<Icons.SearchOutlined />}></HeaderButton>
    </Form>
  );
};

import { TableFetchParams, TableFilter } from '../types';

const cleanizeId = (id: string | number) => {
  let idStr = id.toString();

  if (idStr.includes('search')) {
    let match = idStr.match(/\[(.*?)\]/);
    return match ? match[1] : '';
  }

  return id;
};

export const tableQueryMaker = (params: TableFetchParams) => {
  const filters = params.filters.length ? params.filters.reduce((acc, { id, value }) => ({ ...acc, [id.toString().includes('search') ? id : `filters[${id}]`]: value }), {}) : {};
  const sort = params.sortBy.reduce((acc, { id, desc }) => ({ ...acc, sort_column: cleanizeId(id), sort_order: desc ? 'desc' : 'asc' }), {});
  const page = params.pageIndex + 1;

  return { ...filters, ...sort, page, per_page: params.pageSize };
};

export const tableFilterQueryMaker = (filters: TableFilter[]) => {
  return filters.reduce((acc, { id, value }) => ({ ...acc, [id.toString().includes('search') ? id : `filters[${id}]`]: value }), {});
};

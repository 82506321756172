import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetLanguagesRepoType, IGetLanguagesRepo } from '../repos';
import type { ILanguage } from '../interfaces';

export const useLanguages = (query = {}, options?: UseQueryOptions<ILanguage[], Error, ILanguage[], ['languages', any]>) => {
    const getLanguagesRepo = useService<IGetLanguagesRepo>(GetLanguagesRepoType);

    return useQuery<ILanguage[], Error, ILanguage[], ['languages', any]>(
        ['languages', query],
        async () => {
            const result = await getLanguagesRepo.execute();
            if (result.status === 200) {
                return result.response;
            } else {
                throw new Error(result.response);
            }
        },
        options,
    );
};

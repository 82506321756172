import React from 'react';
import { NextTable } from '@core/table';

import { useTranslationsTable } from '../hooks';

export const TranslationsTable = () => {
    const { columns, getRowProps, context } = useTranslationsTable();

    return <NextTable context={context} columns={columns} getRowProps={getRowProps} pagination={false} sortable={false} />;
};

import { IObjectMobileGroup } from '../interfaces';

export class ObjectMobileGroupsMapper {
  static toDomain(data: any): IObjectMobileGroup {
    return {
      assigned: data.assigned_mobile_groups,
      others: data.others_mobile_groups,
    };
  }
}

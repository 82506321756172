import { IAuthUser, IAuthUserPersistence } from '@modules/auth/interfaces';

export class AuthUserMapper {
  public static toDomain(user: IAuthUserPersistence): IAuthUser {
    return {
      comment: user.comment,
      createdAt: user.created_at,
      id: user.id,
      isOnline: user.is_online,
      loginAt: user.login_at,
      mobile: user.mobile,
      roleId: user.role_id,
      statusId: user.status_id,
      updatedAt: user.updated_at,
      username: user.username,
    };
  }
}

import React, { FC } from 'react';
import { useCloseModal } from '@core/router';

import { CreateTranslationProvider } from '../contexts';
import { CreateTranslation } from '../containers';

export const CreateTranslationPage: FC = () => {
    const closeModal = useCloseModal();

    const onClose = React.useCallback(() => {
        closeModal('/translations');
    }, [closeModal]);

    return (
        <CreateTranslationProvider onCancel={onClose} onSucceed={onClose}>
            <CreateTranslation />
        </CreateTranslationProvider>
    );
};

import { useCallback, useMemo } from 'react';

import { useLayout } from '@modules/layout';
import { useAuth, useLogout } from '@modules/auth';
import { useLanguages } from '@modules/translations';

export const useHeader = () => {
  const { state: layoutState, toggleSidebar } = useLayout();
  const { user } = useAuth();
  const { logout } = useLogout();
  const { data: languages } = useLanguages();

  const changeLanguage = useCallback((lng: string) => {
    localStorage.setItem('i18nextLng', lng);
    window.location.reload();
  }, []);

  const firstName = useMemo<string>(() => user.data?.username || '', [user.data]);

  const avatarText = useMemo<string>(() => firstName[0], [firstName]);

  return { sidebarIsOpen: layoutState.sidebar.isOpen, toggleSidebar, avatarText, logout, changeLanguage, languages };
};

import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { IObject } from '../interfaces';

export type IGetObjectByIdRepoResponse = IRepoResponse<200, IObject> | IRepoResponse<400 | 500, string>;

export type IGetObjectByIdRepo = IRepo<[any], IGetObjectByIdRepoResponse>;

export const GetObjectByIdRepoType = Symbol.for('GetObjectByIdRepo');

@bind(GetObjectByIdRepoType)
export class GetObjectByIdRepo implements IGetObjectByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker(`/objects/${id}`, { relations: ['mobile_groups', 'region'] }) });

      if (result.status === 200) {
        const data: IObject = result.data.data;
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

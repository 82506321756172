import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Router as BaseRouter } from 'react-router-dom';
import LoginPage from '../pages/Login';

import routes from '../constants/routes';
import { useDispatch } from 'react-redux';
import { authFetchAction } from '../actions/auth';
import history from '../core/configs/history';
import { NextModule } from '../@next';
import '@infra/i18n'

const Router: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authFetchAction({}));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <BaseRouter history={history}>
        <React.Suspense fallback={() => 'Loading...'}>
          <Route component={NextModule} />
          <Route path={routes.login.index} component={LoginPage} />
        </React.Suspense>
      </BaseRouter>
    </BrowserRouter>
  );
};

export default React.memo(Router);

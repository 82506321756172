import * as React from 'react';
import { TableFetchParams, tableFilterQueryMaker, TableState } from '@core/table';
import { useService } from '@core/inversify-react';

import { IGetTranslationRepo, GetTranslationRepoType } from '../../repos';
import { TranslationsTableContext } from '../../contexts';

export const useTranslationsTableContext = () => {
    const getTranslationsRepo = useService<IGetTranslationRepo>(GetTranslationRepoType);

    const onFetch = React.useCallback(
        async (params: TableFetchParams) => {
            const result = await getTranslationsRepo.execute(tableFilterQueryMaker(params.filters));

            if (result.status === 200) {
                return {
                    data: result.response.data,
                    total: result.response.total,
                };
            } else {
                throw new Error(result.response);
            }
        },
        [getTranslationsRepo],
    );

    const defaultTableState = React.useMemo<Partial<TableState>>(
        () => ({
            pageSize: 999,
        }),
        [],
    );

    return { onFetch, context: TranslationsTableContext, defaultTableState };
};

import { FC } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import { CellProps } from 'react-table';

export const OverflowTag = styled(Tag)`
  margin: 0;

  &:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    opacity: 1;
    z-index: 19;
  }
`;

export const OverflowStatusTag = styled(Tag)`
  margin: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    opacity: 1;
    z-index: 19;
  }
`

export const TagCell: FC<CellProps<any>> = ({ cell: { value } }) => <OverflowTag>{value}</OverflowTag>;

import React, { useMemo } from 'react';
import * as Icons from '@ant-design/icons';
import { useSocket } from './use-socket';

export const useSocketStatus = () => {
    const { isConnected } = useSocket()
    const icon = useMemo(() => {
        switch (isConnected) {
            case true:
                return <Icons.WifiOutlined />;
            case false:
                return <Icons.WifiOutlined />;
            default:
                return <Icons.SyncOutlined spin={true} />;
        }
    }, [isConnected]);

    const color = useMemo(() => {
        return isConnected ? 'rgb(243 243 243)' : '#000'
    }, [isConnected])

    const bgColor = useMemo(() => {
        return isConnected ? 'rgb(26 113 26)' : 'rgb(134, 134, 134)'
    }, [isConnected])

    const title = useMemo(() => {
        switch (isConnected) {
            case true:
                return 'Online';
            case false:
                return 'Offline';
            default:
                return 'Waiting';
        }
    }, [isConnected]);

    return {
        isConnected,
        icon,
        title,
        bgColor,
        color
    };
};

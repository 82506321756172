import { useContext, useEffect } from 'react';
import { SocketContext } from '../contexts';

export type SocketEventType = 'alarm' | 'inspections:new' | 'alarm:create' | 'alarm:update' | 'alarm:remove' | 'logout';

export type SocketEventHandler = {
  eventName: SocketEventType;
  eventHandler: (data: any) => any;
};

export const useSocketSubscribe = (events: SocketEventHandler[]) => {
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) {
      return;
    }
    events.forEach(({ eventName, eventHandler }) => {
      socket.on(eventName, eventHandler);
    });

    return () => {
      events.forEach(({ eventName, eventHandler }) => {
        socket.off(eventName, eventHandler);
      });
    };
  }, [events, socket]);
};
